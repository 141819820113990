import React, { createContext, useState, useContext } from 'react';
import { dashboardpromotion } from '../../services/HomeServices/homeService';

const PromotionContext = createContext();

export const usePromotionContext = () => useContext(PromotionContext);

export const PromotionProvider = ({ children }) => {
  const [promotionList, setPromotionList] = useState([]);
  const [loading, setLoading] = useState(false);

  const promotionListServices = async (accessToken) => {
    setLoading(true);
    try {
      const res = await dashboardpromotion(accessToken);
      if (res.success === true) {
        setPromotionList(res?.data);
      }
    } catch (error) {
      console.error("Error fetching promotion list:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PromotionContext.Provider value={{ promotionList, loading, promotionListServices }}>
      {children}
    </PromotionContext.Provider>
  );
};
