import React, { useState, useEffect } from 'react';
import globalLoader from '../../../assets/images/loader.svg';
import { formatDate, translate } from '../../../utility/helper';
import { useLanguage } from '../../Context/languageContext';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { getArticleById } from '../../../services/articleServices/articleServices';
import { useParams } from 'react-router-dom';
import { baseURL2 } from "../../../utility/data";
import { FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';


const ViewAddArticle = () => {
  const accessToken = localStorage.getItem('accessToken');
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState(null);
  const { languageData } = useLanguage();
  const { id } = useParams();

  useEffect(() => {
    handleArticle();
  }, [id]);

  const handleArticle = async () => {
    setLoading(true);
    try {
      const res = await getArticleById(accessToken, id);
      setArticle(res?.data[0]);
    } catch (error) {
      console.error('Error fetching article:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyClick = (content) => {
    if (!content || content.trim() === "") {
      toast.error(translate(languageData, "not_copied"));
      return;
    }
    const tempInput = document.createElement('textarea');
    const textContent = new DOMParser().parseFromString(content, 'text/html').body.textContent;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    tempInput.value = textContent;
    document.body.appendChild(tempInput);
    document.body.appendChild(tempDiv);
    const range = document.createRange();
    range.selectNodeContents(tempDiv);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    document.body.removeChild(tempDiv);

    toast.success(translate(languageData, "Contentcopiedtoclipboard"));
  };

  return (
    <div className="ltr">
      {loading ? (
        <div className="d-flex">
          <img src={globalLoader} className="mx-auto mt-10" alt="loader1" />
        </div>
      ) : (
        <Row>
          <Col>
            <Card className="h-100">
              <Card.Header className="d-flex justify-content-between border-bottom pb-4">
                <h3 className="fw-semibold">{translate(languageData, 'ArticleDetails')}</h3>
                <Button className="fw-semibold" onClick={() => window.location.href = article?.doxcUrl}>{translate(languageData, 'DownloadDocx')}</Button>
              </Card.Header>
              <Card.Body>
                {article ? (
                  <div className="">
                    <Row className="mt-5">
                      <Col xs={12} md={4}>
                        <h5>{translate(languageData, 'TitleOfArticle')} : </h5>
                      </Col>
                      <Col xs={12} md={8} className="mt-3 mt-md-0">
                        <div className="wrap-input100 validate-input mb-0">
                          {article.title || ''}
                          <button className="copy-button" onClick={() => handleCopyClick(article.title)}>
                            <FaCopy />
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col xs={12} md={4}>
                        <h5>{translate(languageData, 'AddArtiLead')} : </h5>
                      </Col>
                      <Col xs={12} md={8} className="mt-3 mt-md-0">
                        <div className="wrap-input100 validate-input mb-0">
                          {article.lead || ''}
                          <button className="copy-button" onClick={() => handleCopyClick(article.lead)}>
                            <FaCopy />
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col xs={12} md={4}>
                        <h5>{translate(languageData, 'sidebarContent')} : </h5>
                      </Col>
                      <Col xs={12} md={8} className="mt-3 mt-md-0">
                        <div className="wrap-input100 validate-input d-flex">
                          <div dangerouslySetInnerHTML={{ __html: article.content || '' }} />
                          <button className="copy-button" onClick={() => handleCopyClick(article.content)}>
                            <FaCopy />
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col xs={12} md={4}>
                        <h5>{translate(languageData, 'ProjectName')} : </h5>
                      </Col>
                      <Col xs={12} md={8} className="mt-3 mt-md-0">
                        <div className="wrap-input100 validate-input mb-0">
                          {article.project || ''}
                        </div>
                      </Col>
                    </Row>
                    {article.file && article.file.startsWith(`${baseURL2}/LinkSellingSystem/public/articles/`) &&
                      <Row className="mt-5">
                        <Col xs={12} md={4}>
                          <h5>{translate(languageData, 'image')} : </h5>
                        </Col>
                        <Col xs={12} md={8} className="mt-3 mt-md-0">
                          <div className="wrap-input100 validate-input mb-0">
                            <a href={article.file || ''} download>
                              <img src={article.file || ''} alt="Article Image" className="w-25" />
                            </a>
                          </div>
                        </Col>
                      </Row>}
                    <Row className="mt-5">
                      <Col xs={12} md={4}>
                        <h5>{translate(languageData, 'AddingDate')} : </h5>
                      </Col>
                      <Col xs={12} md={8} className="mt-3 mt-md-0">
                        <div className="wrap-input100 validate-input mb-0">
                          {formatDate(article?.created_at) || '--'}
                        </div>
                      </Col>
                    </Row>
                  </div>) : (<div className='text-center'>{translate(languageData, "notFoundAnyNewRecords")}</div>)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default ViewAddArticle;
